// 组件
import createTable from '@/components/createTable'
import viewFrame from '@/components/viewFrame'
import customeTable from '@/components/customeTable'
import dialogEffect from '@/components/dialogEffect'
// 接口
import { getTicketTypeDetail, deleteZooTicketsType } from '@/services/zoo-tickets.js'
import { getHistoryList } from '@/services/resource'
import Utils from '@/utils/Utils'

// 其他
import loading from '@/utils/loading.json'
import history from '@/common/data/jsonData'
import jsonData from './jsonData'
import { mixin } from '@/mixins/viewDiolog'

export default {
  mixins: [mixin],
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  data () {
    return {
      validateVisible: false,
      buttonObj: [],
      titleObject: {},
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      basicViewItem: jsonData.basicViewItem,
      third_party_information: jsonData.third_party_information,
      guide_and_policies: jsonData.guide_and_policies,
      historyTableItem: history.historyTableItem,
      tableDataViewItem: jsonData.tableDataViewItem,
      dialogData: [],
      batchData: [],
      dialogVisible: false,
      historyTableData: [],
      language: this.$cookies.get('language') || navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0] || 'en'
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
  },
  mounted () {
    const _this = this
    _this.init()
  },
  computed: {
    topVisible () {
      return Utils.isNull(this.$route.params.ticket_package_id_eq)
    }
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    // 初始化
    async init () {
      const _this = this
      _this.setGlobalLoading(true)
	  const res = await getTicketTypeDetail(_this.urlParams.id)
	  if (res.status === 200) {
		  _this.custName = res.data.ticket_type
		  Object.assign(this.titleObject, { title: this.$t('form.zoo.ticket_type') + ': ', data: this.language === 'en' ? _this.custName.basic_information.name.value : _this.custName.basic_information.name.value })
	  }
	  _this.setGlobalLoading(false)
      //  获取历史操作
      getHistoryList({ item_type: 'TicketType', item_id: _this.urlParams.id }).then(res => {
        this.historyTableData = res.data.versions
      })
    },
    forwardToPage (data, name) {
      if (name === 'shop') {
        this.$router.push({
          name: 'shopsManagerDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    // 点击操作按钮
    async handleClick (type, data) {
      if (type?.action === 'edit') {
        this.$router.push({
          name: this.topVisible ? 'zooTicketsTypeEdit' : 'basicTicketsTypeEdit',
          params: {
            id: this.$route.params.id,
            ticket_package_id_eq: this.$route.params.ticket_package_id_eq,
            disabled: this.$route.params.ticket_package_id_eq ? 'true' : 'false',
            ticketType: this.$route.params.ticketType
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(this.$route.params.id, deleteZooTicketsType, this.topVisible ? 'zooTicketsTypeList' : 'basicTicketsTypeList')
      }
    },
    handleOrderItemsTableForwardToPage (data, name) {
      if (name === 'shop_item') {
        this.$router.push({
          name: 'goodsManagerProductDetail',
          params: {
            id: data.id
          }
        })
      } else if (name === 'shop_item_bundle') {
        this.$router.push({
          name: 'bundleDetail',
          params: {
            id: data.id
          }
        })
      }
    }
  }
}
